<div class="h-screen w-screen flex flex-col justify-center items-center text-center">
    <div class="group flex justify-center items-center drop-shadow-md">
        <img class="rounded-lg object-cover h-48 w-48 group-hover:scale-75" src="../assets/images/profilepicture.jpg" alt="Profile Picture">
        <div class="opacity-0 group-hover:opacity-100 group-hover:drop-shadow-xl font-bold duration-300 absolute flex justify-center items-center text-6xl text-white font-oswald">
            <p class="inline-block
                        transition-all
                        duration-500
                        before:content-['']
                        before:absolute
                        before:-bottom-2
                        before:left-1/2
                        before:-translate-x-1/2
                        before:w-0
                        before:h-1
                        before:rounded-full
                        before:opacity-0
                        before:transition-all
                        before:duration-500
                        before:bg-gradient-to-r
                        before:from-zinc-200
                        before:via-slate-50
                        before:to-zinc-200
                        group-hover:before:w-full
                        group-hover:before:opacity-100">cubemaster</p>
        </div>
    </div>
    <span class="type-about text-3xl m-3 font-oswald font-bold"></span>
    <p>Hobby Developer & Mechatronics Student</p>
    <p>Welcome to my portfolio website - WIP!</p>
    <div class="mt-4 text-3xl">
        <a href="https://github.com/cube-m4st3r" target="_blank"><i class="fab fa-github"></i></a>
    </div>
    <ul class="flex">
        <li *ngFor="let card of cards" class="top-2 text-lg cards block relative w-100 bg-card-bg border-1 rounded-lg px-10 py-7 m-6 z-0 overflow-hidden border-card-border hover:shadow-md hover:top-0 hover:border-card-border-h hover:bg-white">
            <h2 class="font-pragatinarrow text-xl m-1">{{card.title}}</h2>
            <p class="font-pragatinarrow text-base">{{card.description}}</p>
        </li>
    </ul>
    <div class="absolute inset-x-0 bottom-5">
        <footer class="font-oswald">I &lt;3 code</footer>
    </div>
</div>
